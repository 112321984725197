<template>
  <v-container fluid>
    <div>
      <h1 class="text-h4">{{ $lang.header.triggerCron }}</h1>
      <v-divider class="my-2"></v-divider>
      <v-row v-if="err || success || successExe" dense no-gutters class="px-0">
        <v-col v-if="err" cols="12">
          <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
        </v-col>
        <v-col v-if="success" cols="12">
          <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
        </v-col>
        <v-col v-if="successExe" cols="12">
          <v-alert color="success" :data-cy="`${successExe} ${successEventId}`">
            {{ successExe }} <span
              class="clickable-simple"
              @click="copyEventId()"
            >{{ successEventId.eventId }}</span><v-icon class="clickable-simple" @click="copyEventId()">mdi-clipboard-outline</v-icon>
            &nbsp;&nbsp;
            <span>
              <v-btn
                outlined
                class="button-default-width"
                @click="goToEventLogs()"
              >
                {{ $lang.labels.events }}
              </v-btn>
            </span>
            &nbsp;&nbsp;
            <span>
              <v-btn
                outlined
                class="button-default-width"
                @click="goToStepLogs()"
              >
                {{ $lang.labels.steps }}
              </v-btn>
            </span>
            &nbsp;&nbsp;
            <span>
              <v-btn
                outlined
                class="button-default-width"
                @click="goToProcessDebug()"
              >
                {{ $lang.labels.debug }}
              </v-btn>
            </span>
          </v-alert>
        </v-col>
      </v-row>
      <v-row wrap no-gutters class="pb-4">
        <v-col
          v-if="!userCanEdit && !loading"
          cols="12"
          sm="12"
          md="8"
        >
          <v-alert dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider color="accent"></v-tabs-slider>

            <v-tab
              :key="0"
              data-cy="tab-details"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              :key="1"
              data-cy="tab-permissions"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-history"
            >
              {{ $lang.labels.history }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="pb-6 fill-height">
            <v-tab-item
              :key="0"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-row wrap no-gutters justify="space-between" class="py-1">
                    <v-col
                      cols="12"
                      sm="9"
                      class="pr-sm-1"
                    >
                      <div class="d-inline-flex max-width">
                        <v-text-field
                          v-model="cron.name"
                          outlined
                          dense
                          :label="$lang.labels.name"
                          required
                          class="required-asterisk"
                          :rules="[v => !!v || 'Required!']"
                          :persistent-hint="isEdit"
                          :hint="formatHint"
                          :readonly="!userCanEdit"
                          data-cy="cron-name"
                        ></v-text-field>
                        <v-btn
                          icon
                          color="primary"
                          class="ml-2"
                          style="margin-top: 2px"
                          @click="copyName()"
                        >
                          <v-icon>mdi-clipboard-outline</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col v-if="isEdit" cols="3" class="pl-1 text-right">
                      <v-btn
                        class="button-default-width"
                        color="primary"
                        :disabled="!userCanEdit"
                        @click="changeStatus()"
                      >
                        {{ cron.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row wrap no-gutters class="pb-4">
                    <v-col
                      cols="9"
                      class="pr-sm-1"
                    >
                      <v-autocomplete
                        v-model="cron.processId"
                        outlined
                        dense
                        :items="processes"
                        :loading="isLoadingProcesses"
                        :search-input.sync="searchProcesses"
                        hide-no-data
                        item-text="name"
                        item-value="id"
                        :label="$lang.labels.process"
                        :placeholder="$lang.actions.startTyping"
                        prepend-inner-icon="mdi-cloud-search-outline"
                        required
                        clearable
                        :rules="[v => !!v || 'Required!']"
                        class="required-asterisk"
                        :readonly="!userCanEdit"
                        data-cy="cron-processId"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="2"
                    >
                      <p
                        :class="{'pt-1 pl-1 clickable-simple': true, 'color-primary': cron.processId, 'color-secondary': !cron.processId}"
                        @click="cron.processId ? openProcess() : ''"
                      >
                        {{ $lang.actions.openProcess }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="pr-sm-1"
                    >
                      <v-select
                        v-model="seconds"
                        :items="formattedSeconds"
                        :label="$lang.labels.seconds"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        class="required-asterisk"
                        data-cy="cron-seconds"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="pl-sm-1"
                    >
                      <v-select
                        v-model="secondsInterval"
                        :items="formattedMinutesInterval"
                        :label="$lang.labels.secondsInterval"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-secondsInterval"
                        class="required-asterisk"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="pr-sm-1"
                    >
                      <v-select
                        v-model="minutes"
                        :items="formattedSeconds"
                        :label="$lang.labels.minutes"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-minutes"
                        class="required-asterisk"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="pl-sm-1"
                    >
                      <v-select
                        v-model="minutesInterval"
                        :items="formattedMinutesInterval"
                        :label="$lang.labels.minutesInterval"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-minutesInterval"
                        class="required-asterisk"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="pr-sm-1"
                    >
                      <v-select
                        v-model="hours"
                        :items="formattedHours"
                        :label="$lang.labels.hours"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-hours"
                        class="required-asterisk"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="pl-sm-1"
                    >
                      <v-select
                        v-model="hoursInterval"
                        :items="formattedHoursInterval"
                        :label="$lang.labels.hoursInterval"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-hoursInterval"
                        class="required-asterisk"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-select
                        v-model="dayOfMonth"
                        :items="formattedDaysOfMonth"
                        :label="$lang.labels.dayOfMonth"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-dayOfMonth"
                        class="required-asterisk"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-select
                        v-model="month"
                        :items="formattedMonths"
                        :label="$lang.labels.month"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-month"
                        class="required-asterisk"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-select
                        v-model="dayOfWeek"
                        :items="formattedDays"
                        :label="$lang.labels.dayOfWeek"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-dayOfWeek"
                        class="required-asterisk"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="9"
                      class="pr-sm-1"
                    >
                      <p class="pa-0 ma-0 pt-2">{{ $lang.labels.comment }}</p>
                      <div class="mark-class pa-1 mb-2 mt-1 clickable-simple" @click="lock || !userCanEdit ? '' : showMarkdown = true" v-html="compiledMarkdown"></div>
                    </v-col>
                    <v-col cols="3" class="pl-1">
                      <p
                        class="pt-1 pt-6 clickable-simple color-primary"
                        @click="lock || !userCanEdit ? '' : showMarkdown = true"
                      >
                        {{ $lang.actions.edit }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-checkbox
                        v-model="cron.isGdprRelevant"
                        :label="$lang.labels.gdpr"
                        data-cy="cron-isGdprRelevant"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row v-if="err || success" dense no-gutters class="px-0">
                    <v-col v-if="err" cols="12">
                      <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
                    </v-col>
                    <v-col v-if="success" cols="12">
                      <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap justify="space-between">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex align-center">
                        <v-btn
                          color="secondary"
                          class="button-default-width"
                          :to="{ name: 'cron', params: { lang: $lang.current_lang } }"
                        >
                          <v-icon
                            right
                            dark
                            class="ma-0"
                          >
                            mdi-arrow-left
                          </v-icon>
                          <span class="ml-1">{{ $lang.actions.back }}</span>
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="isEdit"
                          :action-text="$lang.actions.areYouSureYouWantToDelete"
                          :action-text-suffix="cron.name"
                          :title="$lang.actions.delete"
                          type="cron"
                          :is-disabled="!userCanDelete"
                          :button-text="$lang.actions.delete"
                          :button-color="'error'"
                          :data-cy="'cron-delete'"
                          :forced-option="true"
                          :trigger-force-logic="triggerForceLogic"
                          :regular-delete-errors-usages="regularDeleteErrorsUsages"
                          class="ml-2"
                          :delete-success="deleteSuccess"
                          @submit="deleteCronFunct($event)"
                          @closeDialog="''"
                          @closeAfterDelete="$router.push({ name: 'cron' })"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <div class="d-inline-flex">
                        <v-btn
                          :disabled="!isEdit || lock || !userCanEdit"
                          color="accent"
                          class="button-default-width mr-2 color-accent-text"
                          @click="execute()"
                        >
                          <v-icon
                            left
                            dark
                            class="mr-1"
                          >
                            mdi-play
                          </v-icon>
                          {{ $lang.actions.execute }}
                        </v-btn>
                        <v-btn
                          :disabled="!valid || lock || !userCanEdit"
                          color="success"
                          class="button-default-width"
                          @click="submit()"
                        >
                          <v-icon
                            left
                            dark
                            class="mr-1"
                          >
                            mdi mdi-floppy
                          </v-icon>
                          {{ $lang.actions.submit }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="1"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              class="fill-height"
            >
              <v-card class="py-1 fill-height">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col cols="12">
                      <Table
                        :items="history"
                        @fetchHistory="fetchHistory($event)"
                        @restoreHistory="restoreCron($event)"
                      ></Table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-dialog v-if="showMarkdown" v-model="showMarkdown" max-width="80%" width="80%">
        <markdown
          :markdown-data="cron.comment"
          @save="cron.comment = $event"
          @closeDialog="showMarkdown = false"
        ></markdown>
      </v-dialog>
    </div>
  </v-container >
</template>

<script>
import Table from '@/components/ui/tables/HistoryDatatable'
import Markdown from '@/components/ui/modals/Markdown'
import {
  disableTriggerCronUsingGET as disableCron,
  enableTriggerCronUsingGET as enableCron,
  getTriggerCronByIdUsingGET as getCron,
  updateTriggerCronUsingPUT as updateCron,
  createTriggerCronUsingPOST as createCron,
  getProcessesUsingGET as getProcesses,
  getProcessByIdUsingGET as getProcess,
  getRolesUsingGET as getRoles,
  executeUsingGET as executeCron,
  getHistoriesUsingGET as getHistories,
  deleteCronTriggerUsingDELETE as deleteCron,
  deleteModuleUsingDELETE as deleteModule,
  getValidationRuleByIdUsingGET as getValidation
} from '@/utils/api'
import copy from 'copy-to-clipboard'
import { marked } from 'marked'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'

export default {
  components: {
    ActionButtonWithConfirmation,
    Table,
    Markdown,
    UserRolesSelect
  },
  data() {
    return {
      showMarkdown: false,
      tab: 0,
      err: '',
      success: '',
      successExe: '',
      successEventId: '',
      valid: false,
      isEdit: false,
      loading: true,
      isLoadingProcesses: false,
      lock: false,
      cron: {
        createdOn: '',
        id: '',
        modifiedOn: '',
        name: '',
        comment: '',
        processId: '',
        status: '',
        triggerCondition: '',
        isGdprRelevant: false,
        roles: []
      },
      processes: [],
      searchProcesses: '',
      seconds: 0,
      secondsInterval: null,
      minutes: 0,
      minutesInterval: null,
      hours: '*',
      hoursInterval: null,
      dayOfMonth: '*',
      month: '*',
      dayOfWeek: '*',
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      history: { items: [], meta: {} },
      isSuperUser: false,
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false
    }
  },
  computed: {
    compiledMarkdown: function() {
      return marked.parse(this.cron?.comment) || ''
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    },
    formattedDaysOfMonth() {
      const arr = [{
        text: '*',
        value: '*'
      }]

      for (let i = 1; i < 32; i++) {
        arr.push({
          text: i,
          value: i
        })
      }

      return arr
    },
    formattedDays() {
      const arr = [{
        text: '*',
        value: '*'
      }]

      for (let i = 1; i < 8; i++) {
        arr.push({
          text: this.$lang.days[i],
          value: i
        })
      }

      return arr
    },
    formattedMonths() {
      const arr = [{
        text: '*',
        value: '*'
      }]

      for (let i = 1; i < 12; i++) {
        arr.push({
          text: this.$lang.months[i],
          value: i
        })
      }

      return arr
    },
    formattedHoursInterval() {
      const arr = [{
        text: 'None',
        value: null
      }]

      for (let i = 1; i < 13; i++) {
        arr.push({
          text: i,
          value: i
        })
      }

      return arr
    },
    formattedHours() {
      const arr = [{
        text: '*',
        value: '*'
      }]

      for (let i = 0; i < 24; i++) {
        arr.push({
          text: i,
          value: i
        })
      }

      return arr
    },
    formattedMinutesInterval() {
      const arr = [{
        text: 'None',
        value: null
      }]

      for (let i = 1; i < 31; i++) {
        arr.push({
          text: i,
          value: i
        })
      }

      return arr
    },
    formattedSeconds() {
      const arr = [{
        text: '*',
        value: '*'
      }]

      for (let i = 0; i < 60; i++) {
        arr.push({
          text: i,
          value: i
        })
      }

      return arr
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.cron.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.cron.modifiedOn)}, ID: ${this.cron.id}`
    }
  },
  watch: {
    searchProcesses: {
      handler(val) {
        if (val && val.length > 1) this.searchProcessesFunction(val)
      }
    },
    hoursInterval: {
      handler(val) {
        if (val) this.hours = '*'
      }
    },
    minutesInterval: {
      handler(val) {
        if (val) this.minutes = '*'
      }
    },
    secondsInterval: {
      handler(val) {
        if (val) this.seconds = '*'
      }
    }
  },
  created() {
    this.isEdit = this.$route.name === 'cronEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'TRIGGER_DELETER') || this.isSuperUser
    }

    this.init()
      .then(() => {
        if (this.isEdit && this.$route.params.id) {
          this.loading = true

          getCron({ id: this.$route.params.id })
            .then((res) => {
              this.cron = res.data.data

              this.editRolesIds = this.cron.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
              this.useRolesIds = this.cron.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
              this.viewRolesIds = this.cron.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

              this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

              if (this.cron.comment === null) {
                this.cron.comment = ''
              }

              this.deconstructTriggerCondition()

              getProcess({ id: res.data.data.processId })
                .then((res) => {
                  this.processes = [res.data.data]

                  this.loading = false
                })
                .catch((err) => {
                  this.err = err
                })

            })
            .catch((err) => {
              this.err = err
            })
        } else {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          if (this.$route.params.copy) {
            this.cron = this.$route.params.copy
            if (this.cron.comment === null) {
              this.cron.comment = ''
            }

            this.deconstructTriggerCondition()

            getProcess({ id: this.cron.processId })
              .then((res) => {
                this.processes = [res.data.data]

                this.loading = false
              })
              .catch((err) => {
                this.err = err
              })
          } else {
            this.loading = false
          }
        }
      })
  },
  methods: {
    copyName() {
      if (this.cron.name) {
        copy(this.cron.name)
        this.success = this.$lang.success.copiedClipboard
        setTimeout(() => this.success = '', 5000)
      } else {
        this.err = this.$lang.errors.nothingToCopy
        setTimeout(() => this.err = '', 5000)
      }
    },
    deleteCronFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteCron({ id: this.cron.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.err = err
        })
    },
    openProcess() {
      if (this.cron.processId) window.open(`/${localStorage.selectedLanguage || 'en'}/processes/edit/${this.cron.processId}`, '_blank')
    },
    fetchHistory(options) {
      if (!this.cron.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.cron.id
      obj.resourceType = 'TRIGGER_CRON'

      getHistories(obj)
        .then((res) => {
          this.history = res.data.data
        })
        .catch((err) => {
          this.err = err
        })
    },
    restoreCron(data) {
      this.cron = data

      if (!this.cron.comment) this.cron.comment = ''

      if (this.cron && this.cron.roles) {
        this.editRolesIds = this.cron.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.cron.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.cron.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
      }

      this.deconstructTriggerCondition()

      getProcess({ id: this.cron.processId })
        .then((res) => {
          this.processes = [res.data.data]

          this.loading = false
        })
        .catch((err) => {
          this.err = err
        })
    },
    init() {
      return new Promise((resolve) => {
        getRoles()
          .then((res) => {
            this.allRoles = res.data.data.items
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    deconstructTriggerCondition() {
      const tempTriggerCondition = this.cron.triggerCondition ? this.cron.triggerCondition.split(' ') : null

      if (tempTriggerCondition) {

        const tempSeconds = tempTriggerCondition[0].split('/')

        this.seconds = tempSeconds[0] === '*' ? '*' : Number(tempSeconds[0])
        this.secondsInterval = tempSeconds.length > 1 ? Number(tempSeconds[1]) : null

        const tempMinutes = tempTriggerCondition[1].split('/')

        this.minutes = tempMinutes[0] === '*' ? '*' : Number(tempMinutes[0])
        this.minutesInterval = tempMinutes.length > 1 ? Number(tempMinutes[1]) : null

        const tempHours = tempTriggerCondition[2].split('/')

        this.hours = tempHours[0] === '*' ? '*' : Number(tempHours[0])
        this.hoursInterval = tempHours.length > 1 ? Number(tempHours[1]) : null

        this.dayOfMonth = tempTriggerCondition[3] === '*' ? '*' : Number(tempTriggerCondition[3])

        this.month = tempTriggerCondition[4] === '*' ? '*' : Number(tempTriggerCondition[4])

        this.dayOfWeek = tempTriggerCondition[5] === '*' ? '*' : Number(tempTriggerCondition[5])
      }
    },
    constructTriggerCondition() {
      return `${this.seconds}${this.secondsInterval ? `/${this.secondsInterval}` : ''} ${this.minutes}${this.minutesInterval ? `/${this.minutesInterval}` : ''} ${this.hours}${this.hoursInterval ? `/${this.hoursInterval}` : ''} ${this.dayOfMonth} ${this.month} ${this.dayOfWeek}`
    },
    searchProcessesFunction(val = '') {
      this.isLoadingProcesses = true
      getProcesses({
        page: 1,
        size: 10000,
        name: val || ''
      })
        .then((res) => {
          this.processes = res.data.data.items
          this.isLoadingProcesses = false
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.err = err
        })
    },
    copyEventId() {
      copy(this.successEventId.eventId)
    },
    goToEventLogs() {
      const routeData = this.$router.resolve({
        name: 'logs-events',
        query: {
          eventId: this.successEventId.eventId,
          processId: this.successEventId.processId,
          dateFrom: this.successEventId.dateFrom,
          dateTill: this.successEventId.dateTill,
          triggerId: this.$route.params.id
        }
      })

      window.open(routeData.href, '_blank')
    },
    goToStepLogs() {
      const routeData = this.$router.resolve({
        name: 'logs-steps',
        query: {
          eventId: this.successEventId.eventId,
          processId: this.successEventId.processId,
          dateFrom: this.successEventId.dateFrom,
          dateTill: this.successEventId.dateTill
        }
      })

      window.open(routeData.href, '_blank')
    },
    goToProcessDebug() {
      const routeData = this.$router.resolve({
        name: 'processEdit',
        params: { id: this.successEventId.processId },
        query: {
          eventId: this.successEventId.eventId
        }
      })

      window.open(routeData.href, '_blank')
    },
    async execute() {
      this.lock = true

      this.successEventId = {
        eventId: '',
        processId: '',
        dateFrom: '',
        dateTill: ''
      }

      let res = null

      const { id, processId } = this.cron

      try {

        res = await executeCron({ id })

        if (res && res.status !== 200) {
          this.err = this.$lang.errors.cronExecute
          setTimeout(() => this.err = '', 5000)
          this.lock = false

          return
        }
        this.successExe = this.$lang.success.cronExecute
        this.successEventId = {
          eventId: res.data.data.eventId,
          processId: processId,
          dateFrom: this.$options.filters.formatDate(new Date),
          dateTill: this.$options.filters.formatDate(new Date)
        }
        setTimeout(() => this.successExe = '', 10000)
        this.lock = false

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    },
    async submit() {
      this.err = ''

      if (this.editRolesIds.length < 1 && !this.isSuperUser) {
        this.err = this.$lang.errors.editRoleCreate
        setTimeout(() => this.err = '', 5000)
      }

      this.lock = true

      let res = null

      const { id } = this.cron

      if (!this.isEdit) {
        delete this.cron.id
        this.cron.status = 'ACTIVE'
      }
      delete this.cron.createdOn
      delete this.cron.modifiedOn

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      this.cron.roles = tempRoles

      this.cron.triggerCondition = this.constructTriggerCondition()

      try {

        res = this.isEdit ? await updateCron({
          id,
          triggerCronRequest: this.cron
        }) : await createCron({ triggerCronRequest: this.cron })

        if (res && res.status !== 200) {
          this.err = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.cronUpdate : this.$lang.errors.cronCreate)
          setTimeout(() => this.err = '', 5000)
          this.lock = false

          return
        }
        this.success = this.isEdit ? this.$lang.success.cronUpdated : this.$lang.success.cronCreated
        this.lock = false

        if (!this.isEdit) {
          this.cron = res.data.data
          this.isEdit = true
          this.$router.push({
            name: 'cronEdit',
            params: {
              id: res.data.data.id
            }
          })
        }

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    },
    async changeStatus() {
      this.lock = true
      try {
        const res = this.cron.status === 'ACTIVE' ? await disableCron({ id: this.cron.id }) : await enableCron({ id: this.cron.id })

        if (res.status !== 200) {
          this.err = this.$lang.errors.cronStatusUpdate
          setTimeout(() => this.err = '', 5000)
          this.lock = false
        }
        this.success = this.cron.status !== 'ACTIVE' ? this.$lang.success.cronActivated : this.$lang.success.cronDeactivated

        this.cron.status = res.data.data.status

        this.lock = false

        setTimeout(() => this.success = '', 5000)

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    }
  }
}
</script>
